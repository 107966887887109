import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/data",
    name: "data",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Data.vue"),
  },
  {
    path: "/report",
    name: "report",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Report.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
